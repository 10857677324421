<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as QUOTATION_EXTRA_MODULE_TYPES } from './store'
import { ROUTES as QUOTATION_EXTRA_MODULE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import QuotationExtraModuleTable from './QuotationExtraModuleTable'
import QuotationExtraModuleCommonFilters from './QuotationExtraModuleCommonFilters'
import QuotationExtraModuleEdit from './QuotationExtraModuleEdit.vue'

export default {
  name: 'QuotationExtraModuleList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': QuotationExtraModuleTable,
    'common-filters': QuotationExtraModuleCommonFilters,
    'component-edit': QuotationExtraModuleEdit,
    'component-detail': QuotationExtraModuleEdit
  },
  data () {
    return {
      QUOTATION_EXTRA_MODULE_ROUTES,
      title: this.$t('Quotation Extra Modules'),
      rolePerm: ['quotation_quotationextramodule_list'],
      actionEnablePermission: ['quotation_quotationextramodule_enable'],
      actionDisablePermission: ['quotation_quotationextramodule_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 4 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 4 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 4 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.QUOTATION_EXTRA_MODULE_ROUTES.QUOTATION_EXTRA_MODULE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('quotation_quotationextramodule_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.MUTATIONS.setSelectedList,
      setSelectAll: QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...QUOTATION_EXTRA_MODULE_TYPES.GENERIC.quotation.quotationextramodule.LIST.ACTIONS
    })
  }
}
</script>
